.add-users {
  max-height: 500px;
  overflow-y: auto;
  margin: 0 !important;
  max-width: 100% !important;
  min-height: 300px;
}

.add-users__content,
.course-deadlines__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.add-users-modal__courses {
  flex: 1 0 20%;
}

.add-users-modal__course {
  width: 130px;
  height: 80px;
  background: #280096;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px !important;
  outline: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  padding: 0 !important;
}

.add-users-modal__remove {
  margin-bottom: 15px;
}

.import-progress {
  list-style: none;
  padding: 0;
}

.import-progress__item {
  display: flex;
  padding: 10px 0;
}

.import-deactivation__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
}

.import-deactivation__actions-clear {
  padding: 0;
}

.import-progress__item--loading {
  opacity: 0.8;
}

.import-progress__item--error {
  color: red !important;
}

@media screen and (max-width: 800px) {
  input[type="file"] {
    max-width: 50% !important;
  }
}
