.path {
  margin-bottom: 40px;
  background: white;
  border-radius: 40px;
  padding: 40px;
}

:global(.path > .ui.segment) {
  background: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.internal {
  display: none;
}

.internal > * {
  text-transform: initial !important;
}

.internal:last-of-type {
  display: block;
  margin-top: 50px;
  margin-bottom: 30px;
}
