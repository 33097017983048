.course-deadline-segment {
  cursor: pointer;
  border: 0;
}

.course-deadline-segment .disabled {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: rgba(0, 0, 0, 0.6) !important;;
  opacity: 1 !important;
  cursor: default
}

.course-deadline-segment__title {
  display: flex;
  align-items: center;
}

.course-deadline-segment__item-caret {
  margin-left: auto;
  margin-top: -4px;
}

.course-deadline-segment__item-caret .angle {
  margin-left: 4px;
}

.course-deadline-segment__item {
  display: flex;
  align-items: center;
  padding: 0 !important;
  background: #fff !important;
}

.course-deadline-segment__item-main {
  padding: 1em 0 1em 1em;
  display: flex;
  align-items: center;
  flex: 1;
}

.course-deadline-segment__item-menu {
  margin-left: auto;
  padding-right: 1em;
  display: flex;
}

.course-deadline-segment__item-menu .link {
  margin-left: 8px;
}

.course-deadline-segment__item-menu .link {
  color: #280096 !important;
}

.course-deadline-segment__item:hover {
  background: rgba(255, 255, 255, 0.8) !important;
}

.course-deadline-segment__item-logo {
  width: 30px;
  height: 20px;
  text-align: center;
  margin-right: 8px;
}

.course-deadline-segment__item-logo i {
}

.course-deadline-segment__item img {
  height: 20px;
  margin-right: 4px;
}

.course-deadline-segment > .ui.orange.segment {
  border-color: #E5422D !important;
}

.category-deadline {
  font-size: 1em;
  margin: 0 16px;
  color: #E5422D;
}
