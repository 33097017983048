.users-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
  border: 1px solid white;
}

.users-segment {
  cursor: pointer;
}

.users-segment form {
  background: #fff;
}

.users-segment__form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0 !important;
}

.users-segment__form-row .field {
  padding: 0 5px 15px 0 !important;
}

.users-segment__form-error {
  padding: 0 0 1em 1em;
}

.users-segment__form-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.users-segment__form-actions div {
  display: flex;
}

.users-segment > .ui.orange.segment:not(.inverted) {
  border-color: #E5422D !important;
}

@media screen and (max-width: 800px) {
  .users-segment__form-row .field {
    padding: 0.5em !important;
    margin: 0 !important;
  }
}