.footer {
  color: rgba(0, 0, 0, 0.4);
  padding: 0 20px;
}

.footer a {
  color: #fff !important;
}

.footer a:hover {
  color: #280096 !important;
}

.footer__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 40px;
}
.myfitness-rights {
  text-align: right;
}
.myfitness-terms {
  margin-bottom: 5px;
}
@media screen and (max-width: 800px) {
  .footer__content {
    height: 100px;
    padding: 12px;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 60px;
  }
}
