@font-face {
  font-family: natomPro;
  src: url(Natom-Regular.woff);
}

@font-face {
  font-family: natomProBold;
  src: url(Natom-Bold.woff);
}

@font-face {
  font-family: inter;
  src: url(Inter-Regular.ttf);
}

html,
body {
  margin: 0;
  line-height: 24px;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: natomPro, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
.link {
  padding: 15px 0;
  text-decoration: none;
  cursor: pointer !important;
}

a .disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.4) !important;
}

.air {
  width: 100%;
  height: 50px;
}

.wize a,
.link {
  color: #fff;
}

.wize a:hover,
.link:hover {
  color: #280096;
}

.container {
  max-width: 1128px;
  margin: 0 auto;
}

.wize {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #e5422d;
}

.wize-myfitness {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
    to bottom,
    #e0e0e0 0%,
    #d3d3d3 25%,
    #c0c0c0 50%,
    #b3b3b3 75%,
    #a6a6a6 100%
  );
}

.bold-text {
  font-family: natomProBold, sans-serif !important;
}

.white-text {
  color: #fff !important;
}

.orange-text {
  color: #e5422d !important;
}

.blue-text {
  color: #280096 !important;
}

.error-text {
  color: #cd0707 !important;
}

.secondary-text {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: normal !important;
  font-size: 12px !important;
}

.ui.orange.button:not(.basic) {
  background-color: #e5422d !important;
}

.ui.blue.button:not(.basic) {
  background-color: #280096 !important;
}

.ui.basic.orange.button,
.ui.basic.orange.button:hover,
.ui.basic.orange.button:focus,
.ui.basic.orange.button:active {
  box-shadow: 0 0 0 1px #e5422d inset !important;
  color: #e5422d !important;
}

.ui.basic.blue.button,
.ui.basic.blue.button:hover,
.ui.basic.blue.button:focus,
.ui.basic.blue.button:active {
  box-shadow: 0 0 0 1px #280096 inset !important;
  color: #280096 !important;
}

.segment--blue {
  background: #280096 !important;
  border-color: #280096;
  color: white !important;
}

.ui.form input:focus {
  border-color: #280096 !important;
}

.ui.modal > .header {
  background: #280096 !important;
  color: #fff !important;
}

.popup > .react-calendar {
  border: 0 !important;
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: portrait) {
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 800px) {
  .hide-mobile {
    display: none !important;
  }

  .air {
    height: 20px;
  }
}

@media screen and (min-width: 801px) {
  .hide-desktop {
    display: none !important;
  }
}
