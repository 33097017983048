.courses {
  margin-bottom: 40px;
}

.internal {
  display: none;
}

.internal > * {
  text-transform: initial !important;
}

.internal:last-of-type {
  display: block;
  margin-top: 50px;
  margin-bottom: 30px;
}
