.template {
  margin-bottom: 40px;
  background: white;
  border-radius: 40px;
  padding: 40px;
}

.template-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.template-item a {
  color: #280096;
  margin-left: 16px;
  padding: 0;
}
