.forgot-password {
  width: 100%;
  display: flex;
  justify-content: center;
}

.forgot-password__form {
  border: 1px solid #fff;
  border-radius: 40px;
  font-size: 1.2em !important;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
}

.forgot-password .ui.buttons .or:before {
  background: #E5422D !important;
  color: #fff !important;
}
