.menu {
  background: inherit;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.menu__logo {
  height: 54px;
  display: flex;
  align-items: center;
}

.logo-big {
  height: 85px;
  margin-top: 15px;
}

.logo-small {
  height: 40px;
}

.menu__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
}

.menu__content-item {
  background: transparent !important;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  border-top: 2px solid transparent;
  color: #fff;
  margin: 0 40px 0 0;
  white-space: nowrap;
  font-weight: normal !important;
  font-size: 16px !important;
}

.menu__content-item-myfitness {
  background: transparent !important;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  border-top: 2px solid transparent;
  color: #312373;
  margin: 0 40px 0 0;
  white-space: nowrap;
  font-weight: normal !important;
  font-size: 16px !important;
}

.menu .menu__content-item-myfitness--active {
  font-weight: bold;
  border-bottom: 1px solid #e30613 !important;
}

.menu .menu__content-item--active {
  font-weight: bold;
  border-bottom: 1px solid #fff;
}

.menu__content-item-myfitness:hover {
  color: #e30613;
  border-bottom: 1px solid #fff;
}

.menu .menu__content-item-myfitness:hover {
  color: #312373 !important;
  border-bottom: 1px solid #e30613 !important;
}

.menu .menu__content-item:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.menu__dropdown-item {
  height: 24px;
  width: 120px;
  display: flex;
  align-items: center;
  color: #000 !important;
}

@media screen and (max-width: 800px) {
  .menu {
    padding: 10px;
  }

  .menu__content-item {
    margin: 0;
    width: 100%;
  }

  .menu__content-mobile {
    width: 100%;
    background: #e5422d;
    z-index: 999;
    padding-bottom: 40px;
  }

  .menu__content-btn {
    border-width: 0 !important;
  }
}

.lang-dropdown {
  margin-right: 2rem;
}
