.users-segment__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.users-segment__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 1em !important;
  height: 50px;
}

.users-segment__item > label {
  color: #fff !important;
}

.users-segment__item--selected {
  background: rgba(255, 255, 255, 0.8) !important;
}

.users-segment__item:not(.users-segment__item--static):hover,
.users-segment__item:not(.users-segment__item--static):hover {
  background: rgba(255, 255, 255, 0.8) !important;
}

.users-segment__item-progress {
  flex: 1;
}

@media screen and (max-width: 800px) {
  .users-segment__item--static:not(.form-message) {
    flex-direction: column;
    align-items: stretch;
    height: 100px;
  }

  .users-segment__item--static-checkbox {
    order: 4;
    margin-top: 12px;
  }
}
