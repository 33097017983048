.category-segment {
  cursor: pointer;
  border: 0;
}

.courses > .category-segment > .ui.segment {
  background: #280096;
  border-color: #280096;
  color: #fff;
}

.path-segments {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.path-segments--green {
  border-color: rgba(44, 228, 32, 1) !important;
}

.path-segments--red {
  border-color: rgba(225, 71, 48, 1) !important;
}

.path-segment {
  background: rgba(0, 0, 0, 0) !important;
  color: #000 !important;
  padding: 12px !important;
}

.path-segments--green > .path-segment {
  background: rgba(44, 228, 32, 0.2) !important;
}

.path-segments--red > .path-segment {
  background: rgba(225, 71, 48, 0.2) !important;
}

.path-segment--yellow {
  background: rgba(242, 179, 35, 0.2) !important;
  border-color: rgba(242, 179, 35, 1) !important;
}

.category-segment .disabled {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1 !important;
  cursor: default
}

.category-segment__title {
  display: flex;
  align-items: center;
  min-height: 26px;
}

.path-segment > .category-segment__title {
  font-weight: normal;
}

.category-segment__item {
  background: white !important;
  color: black !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.category-segment__item-caret {
  margin-left: auto;
}

.category-segment__item-caret .angle {
  margin-left: 4px;
}

.category-segment__item-main {
  padding: 1em 0 1em 3em !important;
  display: flex;
  align-items: center;
  flex: 1;
}

.category-segment__item-menu {
  margin-left: auto;
  padding-right: 1em;
}

.category-segment__item-menu .link {
  margin-left: 8px;
}

.link--dark {
  color: rgba(0, 0, 0, 0.8) !important;
}

.category-segment__item:hover {
  background: rgba(255, 255, 255, 0.8) !important;
}

.category-segment__item-logo {
  width: 30px;
  height: 20px;
  text-align: center;
  margin-right: 8px;
}

.category-segment__item-logo i {
}

.category-segment__item img {
  height: 20px;
  margin-right: 4px;
}

.category-segment > .ui.orange.segment {
  border-color: #E5422D !important;
}
