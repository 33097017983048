.header {
  z-index: 1;
}

.header--noauth {
  border-bottom: 1px solid rgba(155, 155, 155, 0.1);
  box-shadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.2);
}

.header__logo {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 6px;
  display: flex;
  align-items: center;
  height: 73px;
}

.header__logo img {
  height: 56px;
}

.content {
  max-width: 50em;
  margin: 0 auto;
  position: relative;
}

.modal__image {
  height: 30px;
  float: right;
}

.modal__content {
  display: flex;
  flex-direction: column;
}

.modal__image img {
  max-width: 80px;
}

.modal a {
  color: #E5422D !important;
}

.CookieConsent {
  height: 81px !important;
  background: #000 !important;
  display: flex !important;
  align-items: center !important;
  font-weight: bold !important;
  width: 100vw !important;
  z-index: 2147483640 !important;
}

.CookieConsent .link {
  color: rgba(255, 255, 255, 1) !important;
  text-decoration: underline !important;
}

.CookieConsent button {
  cursor: pointer !important;
  outline: 0 !important;
  background: rgba(255, 255, 255, 1) !important;
  color: rgba(0, 0, 0, .6) !important;
  padding: 12px !important;
  border-radius: .28571429rem !important;
  min-width: 120px;
}

@media screen and (max-width: 800px) {
  .CookieConsent {
    display: flex;
    flex-direction: column;
    height: initial !important;
  }

  .CookieConsent div {
    flex: initial !important;
  }

  .CookieConsent button {
    font-size: 90% !important;
    padding: 8px !important;
  }
}
