.klib-r {
  z-index: 999;
  position: absolute;
  right: 20px;
  height: 200px;
}

.klib-l {
  z-index: 999;
  position: absolute;
  left: 0;
  top: 120px;
  width: 200px;
}

.splash {
  padding-bottom: 20px;
}

.splash-top {
  z-index: 1;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: #280096;*/
}

.splash h1 {
  font-size: 2.2em;
}

.splash h2 {
  font-size: 3em;
  line-height: 1em;
}

.quote-text {
  text-transform: uppercase;
}

.myfitness-text {
  color: #312373;
}

.splash-bottom {
  fill: #280096;
}

.ui.blue.progress .bar {
  background-color: #fff !important;
}

.splash-content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.author {
  float: right;
  font-size: 2em;
}

.splash-content__image {
  padding-top: 20px;
  padding-left: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash-image {
  height: 100%;
  display: flex;
  align-items: center;
}

.splash-image img {
  max-height: 270px;
}

@media all and (min-width: 1024px) and (max-width: 1366px) and (min-height: 768px) and (max-height: 1366px) {
  .klib-r {
    right: 0;
    top: 30px;
    width: 20px;
    height: 180px;
  }

  .klib-l {
    left: 0;
    top: 260px;
    width: 150px;
  }
}

@media screen and (max-width: 812px) {
  .klib-r {
    right: 0;
    top: 30px;
    width: 8px;
    height: 120px;
  }

  .klib-l {
    left: -30px;
    top: 170px;
    width: 80px;
  }

  .splash-top {
    min-height: 400px;
  }

  .splash h1 {
    font-size: 20px;
    margin-top: 0;
  }

  .splash h2 {
    font-size: 1.5em;
  }

  .splash p {
    font-size: 14px;
  }

  .splash-content {
    display: initial;
  }

  .splash-image {
    justify-content: center;
  }

  .splash-image img {
    max-width: 200px;
  }

  .author {
    font-size: 14px;
  }
}
