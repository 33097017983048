.authentication {
  margin-top: 20px;
}

.authentication h1 {
  font-size: 40px;
}

.authentication p {
  font-size: 16px;
}

.authentication-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.authentication-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  padding: 40px;
  color: #fff;
  height: 400px;
  border: 1px solid #fff;
  text-align: center;
}

.authentication-text-myfitness {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  padding: 40px;
  color: #2e3092;
  height: 400px;
  border: 1px solid #fff;
  text-align: center;
}

.authentication-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.authentication-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.authentication-image img {
  max-height: 270px;
}

.authentication-text .ui.buttons .or:before {
  background: #e5422d !important;
  color: #fff !important;
}

.authentication-text p {
  font-size: 16px !important;
}

.auth-field {
  margin-bottom: 1em;
  width: 100%;
}
/* .auth-field {
  background-color: yellow;
  margin-bottom: 1em;
  width: 100%;
} */

.form-myfitness input {
  background: #b2b2b2 !important;
  text-align: center !important;
}

.form-myfitness input::placeholder {
  color: #fff !important;
}
@media screen and (max-width: 800px) {
  .authentication {
    margin-top: 0;
  }

  .authentication-top {
    max-height: 400px;
    padding: 10px;
  }

  .authentication-text {
    padding-top: 0;
  }

  .authentication h1 {
    font-size: 20px;
  }

  .authentication h2 {
    font-size: 18px;
  }

  .authentication p {
    font-size: 14px;
  }

  .authentication-image img {
    max-width: 200px;
  }
}
