.react-calendar {
  border: 1px solid rgba(34,36,38,.15) !important;
  border-radius: 4px;
}
.react-calendar__tile {
}

.react-calendar__tile--now {
  background: rgba(34,36,38,.15) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #E5422D !important;
}

.react-calendar__tile--active {
  background: #E5422D !important;
  color: white !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
