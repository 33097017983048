.error-top {
  width: 100%;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #280096;
  color: white;
}

.error h1 {
  font-size: 40px;
}

.error p {
  font-size: 26px;
}

.error-bottom {
  fill: #280096;
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.error-content__text {
  padding-top: 30px;
  text-align: center;
}

.error-content__image {
  padding-top: 20px;
}

.error-content__image img {
  max-height: 240px;
}

.error-content > a:hover {
  color: #fff !important;
}

@media screen and (max-width: 800px) {
  .error-top {
    max-height: 400px;
    padding: 10px;
  }

  .error-bottom {
    width: 100%;
  }

  .error h1 {
    font-size: 20px;
  }

  .error h2 {
    font-size: 18px;
  }

  .error p {
    font-size: 14px;
  }

  .error-content__text,
  .error-content__image {
    padding: 10px;
    text-align: center;
  }

  .error-content__image img {
    max-width: 100px;
  }
}
