.landing {
  background: #E5422D;
}

.landing__row-logo {
  display: flex;
  align-items: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.landing__description,
.landing__price-description,
.landing__heading {
  font-size: 1.6em;
}

.landing__description,
.landing__price-description {
  line-height: 1.6em;
}

.column.border {
  margin: 0;
  padding: 0 40px 0 0 !important;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step-container:not(.last) > .step-text,
.step-container:not(.first) > .step {
  border-left: 2px dashed #fff;
}

.step-text {
  color: #fff;
  line-height: 1.2em;
  font-size: 1.2em;
  border-top: 2px solid white;
  padding: 10px;
}

.step {
  font-size: 2em;
  text-align: right;
  width: 100%;
  color: white;
  padding: 10px;
}

.landing-path-icon {
  text-align: center !important;
}

.landing-path-icon > * {
  color: white !important;
}

.landing__price {
  text-transform: uppercase;
  background: #280096;
  border-radius: 50%;
  font-size: 2em;
  padding: 50px 60px;
  max-width: 120px;
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 20px;
}

.landing__cta,
.landing__title {
  font-size: 2.7em;
  line-height: 1.2em;
  text-transform: uppercase;
}

.course__card {
  width: 100%;
  background: #280096;
  border-radius: 25px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 44px;
  font-size: 1.7em;
  outline: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
}

.course__card:hover {
  background: #000;
  color: #E5422D !important;
}

.course__card--black,
.course__card--black:hover {
  background: #000;
}

.course__card-footer {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  padding: 0 8px 8px 0;
}

.course__card img {
  width: 30px;
  height: 30px;
}

.landing__register {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing__button {
  outline: inherit;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
}

.landing__blue {
  background: #280096;
  border-radius: 40px;
  padding: 16px 24px;
  width: 600px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2em;
  text-transform: uppercase;
}

.landing__form {
  background: #280096;
  border-radius: 40px;
  padding: 40px;
}

.landing__blue img {
  height: 30px;
}

.landing__register .thanks {
  font-size: 1.6em;
  line-height: 1.2em;
  color: #fff;
  text-align: center;
}


/* --- TK --- */
.landing-description-tk {
  font-weight: bold;
  letter-spacing: .05rem;
}
.possibility-text {
  color: #fff;
  line-height: 1.2em;
  font-size: 1.2em;
  padding: 0.5rem;
}

.landing__possibilities  {
  border: 2px solid white;
  border-radius: 25px;
}

.landing__possibilities .accordion  {
  /* border: 2px solid white;
  border-top-width: 0; */
  margin: -2px inherit;
}

.landing__possibilities .accordion h3  {
  border-top: 2px solid white;
  /* border-bottom: 2px dashed white; */
  margin: 0 auto -2px auto;
  padding: 1rem;
}

.landing__possibilities .accordion h3:first-child {
  border-top: 0px solid white;
}

.landing__possibilities .accordion .content  {
  line-height: 1.2em;
  font-size: 1.3em;
  padding: 1rem !important;
  max-width: inherit;
  border-top: 2px dashed #fff;
}

.landing__possibilities a {
  color: #fff !important;
  text-decoration: underline;
}

.landing__possibilities a:hover {
  color: #280096 !important;
}

h2.ui.header {
  font-family: natomProBold, sans-serif !important;
  font-size: 2.7rem !important;
  line-height: 1.2em;
  text-transform: uppercase;
  color: white;
  padding: 1rem;
}

@media screen and (max-width: 800px) {
  .landing__row {
    flex-direction: column;
    padding: 10px 20px;
  }

  .landing__row-logo {
    max-width: 300px;
  }

  .landing__cta {
    font-size: 2em;
    text-align: center;
  }

  .landing__title {
    font-size: 1.5em;
  }

  h2.ui.header {
    font-size: 1.7rem !important;
  }

  .landing__description {
    font-size: 1.3em;
  }
  .course__card {
    font-size: 1em;
  }

  .landing__heading {
    font-size: 1.2em;
  }

  .course__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .course__card-footer {
    height: 20px;
  }

  .course__card img {
    width: 20px;
    height: 20px;
  }

  .landing__register {
    font-size: 1.5em;
  }

  .column.border {
    border: none;
  }

  .step-text {
    padding-left: 60px;
  }

  .landing__register {
    margin-top: 30px;
  }

  .landing__register .landing__heading {
    font-size: 0.8em;
  }

  .landing__blue {
    font-size: 0.8em;
  }

  .landing__blue img {
    height: 16px;
  }

  .landing__form {
    background: #280096;
    border-radius: 40px;
    padding: 20px;
  }
}
