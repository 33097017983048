.course-segment {
  cursor: pointer;
  border: 0;
  color: #000 !important;
  padding: 11px !important;
}

.course-segment--gray {
  background: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.course-segment--green {
  background: rgba(44, 228, 32, 0.2) !important;
  border-color: rgba(44, 228, 32, 1) !important;
}

.course-segment--red {
  background: rgba(225, 71, 48, 0.2) !important;
  border-color: rgba(225, 71, 48, 1) !important;
}

.course-segment--yellow {
  background: rgba(242, 179, 35, 0.2) !important;
  border-color: rgba(242, 179, 35, 1) !important;
}

.course-segment__title {
  display: flex;
  align-items: center;
  min-height: 26px;
}

.course-segment__item-main {
  display: flex;
  align-items: center;
}

.course-segment__item-menu {
  margin-left: auto;
  padding-right: 1em;
}

.course-segment__item-menu .link {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.7) !important;
}

.course-segment__item-logo {
  width: 30px;
  height: 20px;
  text-align: center;
  margin-right: 8px;
}

.course-segment__item img {
  height: 20px;
  margin-right: 4px;
}

.course-segment > .ui.orange.segment {
  border-color: #E5422D !important;
}
